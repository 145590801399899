/* src/App.css */

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  position: absolute;
  bottom: 50px; /* Set bottom positioning to avoid overlap with other elements */
  left: 50%; /* Center the slider horizontally */
  transform: translateX(-50%); /* Adjust centering precisely */
  width: 80vw; /* Adjust this if you want even closer to full width */
  z-index: 10; /* Ensure it is above other elements */
  height: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}


.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgb(2, 117, 255);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgb(2, 117, 255);
  cursor: pointer;
}


.time-display {
  position: absolute;
  bottom: 85px;  /* Ensure there is no overlap with the slider */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  color: white; 
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 5px;
  width: auto;  /* Ensure the width here is auto or fits the content */
}
